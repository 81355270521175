<template>
  <v-container class="mt-8">
    <v-card class="greeting-card pa-md-4 mx-lg-auto mt-6" width="800px">
      <v-row class="ma-0 pa-0">
        <v-col cols="12">
          <v-card-title class="text-no-wrap pt-1 ps-2">
            {{ moodle.name | uppercase }}
          </v-card-title>
          <v-card-subtitle class="text-wrap ps-2">
            {{ moodle.description }}
          </v-card-subtitle>
          <v-divider></v-divider>
          <div v-for="(question, index) in questions" :key="index">
            <v-card-text v-if="index == currentIndex" class="text-center mt-5">
              <p class="font-weight-medium subtitle-1">
                {{ question.title }}
              </p>
              <p v-if="question.link">
                <a target="__blank" :href="question.link">Click here to learn more about {{ question.linkText }}</a>
              </p>
              <v-row v-if="hasChild == false" class="mt-8">
                <v-col sm="12" md="6">
                  <v-btn color="primary" @click="restart">
                    Restart Evaluation
                  </v-btn>
                </v-col>
                <v-col sm="12" md="6">
                  <v-btn color="secondary" to="/">
                    Back To Symptoms
                  </v-btn>
                </v-col>
              </v-row>
              <v-row v-if="question.hideAnswer == false || question.children.length > 0" justify="center">
                <v-radio-group v-model="answer" row dense>
                  <v-radio
                    v-for="(item, key) in question.answer"
                    :key="key"
                    :label="item | upText"
                    :value="item"
                  ></v-radio>
                </v-radio-group>
              </v-row>
            </v-card-text>
          </div>
          <v-card-actions v-if="hasChild" class="mb-6">
            <div>
              <v-btn v-if="previousIndex >= 0" color="secondary" @click="previousQuestion">
                Previous
              </v-btn>
            </div>
            <div>
              <v-btn color="primary" right absolute :disabled="answer === null" @click="nextQuestion">
                Next
              </v-btn>
            </div>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { questionCollection } from "@/services/questionService";
import moodule from "@/services/moduleService";

export default {
  name: "Front",
  data: () => ({
    currentIndex: 0,
    previousIndex: -1,
    complete: false,
    hasChild: true,
    questions: [],
    moduleId: null,
    answer: null,
    moodle: {
      name: null,
      description: null,
    },
  }),
  computed: {
    ...mapState({
      user: state => state.user.user,
      isLoggedIn: state => state.user.isLoggedIn,
    }),
  },
  watch: {
    currentIndex(value) {
      if (this.questions[value].children.length === 0) {
        this.hasChild = false;
      }
    },
  },
  created() {
    // eslint-disable-next-line no-extra-semi
    (async () => {
      this.moduleId = this.$route.params.mid;
      this.exists = await this.checkModule(this.moduleId);
      if (this.exists) {
        await this.initialQuestion();
      } else {
        this.$router.push({ name: "error-404" });
      }
    })();
  },
  methods: {
    async initialQuestion() {
      try {
        let initial = false;
        const items = [];
        if (this.questions.length === 0) {
          initial = true;
          await questionCollection
            .where("moduleId", "==", this.moduleId)
            .where("initial", "==", initial)
            .get()
            .then(querySnaphot => {
              querySnaphot.forEach(doc => {
                const data = doc.data();
                data.id = doc.id;
                items.push(data);
              });
            });
        }
        this.questions = items;
      } catch (error) {
        this.$store.dispatch("notification/add", { type: "error", message: error.message });
      }
    },
    async nextQuestion() {
      // eslint-disable-next-line prefer-destructuring
      const children = this.questions[this.currentIndex].children;
      let id = null;
      children.forEach(child => {
        if (child.answer === this.answer) id = child.id;
      });
      await questionCollection
        .doc(id)
        .get()
        .then(doc => {
          if (doc.exists) {
            const data = doc.data();
            data.id = doc.id;
            this.questions.push(data);
          }
        });
      this.currentIndex += 1;
      this.previousIndex = this.currentIndex - 1;
      this.answer = null;
    },
    previousQuestion() {
      this.questions.splice(this.currentIndex, 1);
      this.currentIndex = this.previousIndex;
      this.previousIndex -= 1;
    },
    async checkModule(id) {
      let exists = false;
      await moodule.getModule(id).then(doc => {
        if (doc.exists) {
          const data = doc.data();
          data.id = doc.id;
          this.moodle = data;
          exists = true;
        }
      });

      return exists;
    },
    restart() {
      window.location.reload();
    },
  },
};
</script>

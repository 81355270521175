/* eslint-disable object-curly-newline */
import firebase, { questionCollection } from "@/utils/firebase";

export default {
  async getQuestions() {
    const querySnapshot = await questionCollection.get();

    return querySnapshot;
  },

  async getQuestion(uid) {
    const docSnap = await questionCollection.doc(uid).get();

    return docSnap;
  },

  addQuestion(data) {
    // return questionCollection.doc().add(data)
    return questionCollection.add(data);
  },

  updateQuestion(id, data) {
    return questionCollection.doc(id).update(data);
  },

  updateChildrenArray(id, data) {
    return questionCollection.doc(id).update({ children: firebase.firestore.FieldValue.arrayUnion(data) });
  },

  deleteQuestion(id) {
    return questionCollection.doc(id).delete();
  },
  async getQuestionByField(field, operator, param) {
    return questionCollection.where(field, operator, param).get();
  },
};

export { questionCollection };
